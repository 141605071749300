import { useContext, useEffect, useState } from "react"
import FormContext from "../../../../form.context"
import { Select } from "../../../select"
import { BankImage, SelectedBankDetails } from "./components"
import optionDetails from './option_details.dictionary'

export const BankOptions = ({ onChange }) => {
    const [ bank, setBank ] = useState("BDO")
    const { method }        = useContext(FormContext)

    useEffect(() => {
        onChange(bank)
    }, [ bank ])
    
    return method === "bank" ? (
        <div>
            <div className="h5">
                <strong>Step 1:</strong>
                <div style={{ fontSize: 16, marginTop: 5 }}>
                    Select a Bank to deposit.
                </div>
            </div>
            <div>
                <Select
                    name = "bank"
                    type = "select"
                    value = { bank }
                    options = {
                        Object.keys(optionDetails).map(key => ({ label: key, value: key }))
                    }
                    onChange = { ({ value }) => setBank(value) }
                />
            </div>
            <div style = {{ marginTop: 5 }} >
                <BankImage bank = { bank } />
                <SelectedBankDetails details={ optionDetails[bank] } />
            </div>
            <div
                className = "h5"
                style = {{ marginTop: 20 }}
            >
                <strong>Step 2:</strong>
                <div style={{ fontSize: 16, marginTop: 5 }}>
                    If payment has already been made, fill out the form and attach the proof of payment.
                </div>
            </div>
        </div>
    ) : null
}