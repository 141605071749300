const security = [
    {
        label: "Account Name",
        value: "Faith Academy, Inc."
    },
    {
        label: "Peso Saving Account",
        value: "0391-047565-001"
    },
    {
        label: "Swift Code",
        value: "SETCPHMM"
    }
]

export default security