import { useState, useRef, useEffect } from "react"
import { Input } from '../../input'

export const Converter = ({ pesoRate }) => {
    const [ usd, setUsd ] = useState(1)
    const [ php, setPhp ] = useState(pesoRate)
    const [ toggled, setToggled ] = useState(false)
    const wrapperRef = useRef()

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                wrapperRef.current &&
                !wrapperRef.current.contains(event.target)
            ) setToggled(false)
        }

        document.addEventListener('mousedown', handleClickOutside)
        return () => document.removeEventListener('mousedown', handleClickOutside)
    }, [])

    return (
        <div ref = { wrapperRef }>
            <div
                className="converter-toggle"
                onClick={() => setToggled(prev => !prev)}
            >
                Converter
            </div>
            <div className={`curr-converter ${ toggled && "show"}`}>
                <div>
                    <Input
                        name  = "usd"
                        label = "USD"
                        type  = "numeric"
                        value = { usd }
                        onChange = {({ value }) => {
                                        setUsd(value)
                                        if (value) setPhp(+value * pesoRate)
                                        else setPhp(0)
                                    }}
                    />
                </div>
                <div style = {{ marginTop: 10 }}>
                    <Input
                        name  = "php"
                        label = "PHP"
                        type  = "numeric"
                        value = { php }
                        onChange = {({ value }) => {
                                        setPhp(value)
                                        if (value) setUsd(+value / pesoRate)
                                        else setUsd(0)
                                    }}
                    />
                </div>
            </div>
        </div>
    )
}