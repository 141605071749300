import BDO from './bdo_details.map'
import BPI from './bpi_details.map'
import METROBANK from './metrobank_details.map'
import SECURITY from './securitybank_details.map'
import ROBINSONS from './robinsons_details.map'
import ADELFI from './adelfi_details.map'

const details = {
    "BDO": BDO,
    "BPI": BPI,
    "METROBANK": METROBANK,
    "SECURITY BANK": SECURITY,
    "ROBINSONS": ROBINSONS,
    "ADELFI": ADELFI
}

export default details