const robinsons = [
    {
        label: "Account Name",
        value: "Faith Academy, Inc."
    },
    {
        label: "Peso Savings Account",
        value: "104630100004544"
    },
    {
        label: "Swift Code",
        value: "ROBPPHMQXXX"
    }
]

export default robinsons