import { useState, useEffect } from 'react'
import { FooterCTAs, Header, Methods, PaymentInfo, SuccessPage } from './components'
import './_styles.scss'
import FormContext from './form.context'
import { connect } from '../right_section/components/footer_ctas/components/payment_buttons/api.utils'

export const RightSection = ({
    step,
    setStep
}) => {
    const [ method, setMethod ]     = useState('')
    const [ form, setForm ]         = useState({})
    const [ cardDetails, setCard ]  = useState({})
    const [ isLoading, setLoading ] = useState(false)
    const [ pesoRate, setPesoRate ] = useState(0)

    useEffect(() => {
        window.scrollTo({ top: 0 })
    }, [ step ])

    useEffect(() => {
        setForm({})
    }, [ method ])

    useEffect(() => {
        const session   = localStorage.getItem("paySession")
        const params    = new Proxy(new URLSearchParams(window.location.search), {
                            get: (searchParams, prop) => searchParams.get(prop),
                        })
                        
        if (session) {
            if (params.mayaScc || params.mayaCnl || params.mayaErr) {
                const parseSession = JSON.parse(session)
                if (parseSession.method === "maya") {
                    if (`${ parseSession.timeStamp }` === (params.mayaCnl || params.mayaErr)) {
                        setForm(parseSession.form)
                        setCard(parseSession.cardDetails)
                        setMethod("maya")
                        setStep(2)
                    }
                    else if (params.mayaScc === `${ parseSession.timeStamp }`) setStep(4)
                }
                else localStorage.removeItem("paySession")
            }
            else localStorage.removeItem("paySession")
        }
        else localStorage.removeItem("paySession")

        connect()
            .get("/api/options/peso-rate")
            .then(res => {
                if(res.data?.value) setPesoRate(res.data.value)
            })

    }, [])

    return (
        <FormContext.Provider
            value = {{
                form,
                method,
                cardDetails,
                onCardChange: newCard => setCard(newCard),
                onFormChange: newForm => setForm(newForm),
                step,
                setStep,
                isLoading,
                setLoading
            }}
        >
            <div className={`right-section-wrapper ${ step === 4 ? "success" : '' }`}>
                <div className='payment-form'>
                    <Header
                        show     = { step < 4 }
                        pesoRate = { pesoRate }
                    />
                    <PaymentInfo
                        show = { step === 2 || step === 3 }
                    />
                    <Methods
                        show     = { step === 1 }
                        onSelect = { value => {
                                    setMethod(value)
                                    setStep(prev => prev + 1)
                                }}
                        selected = { method }
                    />
                    <SuccessPage show = { step === 4 } />
                    <FooterCTAs
                        onLeftClick     = {() => {
                                            if (step > 1) setStep(prev => prev - 1)
                                            else window.location.href = "https://faith.edu.ph/"
                                        }}
                        onRightClick    = {() => {
                                            setStep(prev => prev + 1)
                                        }}
                        disabled        = { !method }
                        method          = { method }
                        step            = { step }
                    />
                </div>
            </div>
            {
                isLoading ? (
                    <div className='loading-overlay'>
                        <img
                            src = { require("./loader.gif") }
                            alt = "loader"
                        />
                    </div>
                ) : null
            }
        </FormContext.Provider>
    )
}