import security from '../assets/security.png'
import bdo from '../assets/bdo.png'
import bpi from '../assets/bpi.png'
import metro from '../assets/metro.png'
import adelfi from '../assets/adelfi.jpg'
import robinsons from '../assets/robinsons.png'

const images = {
    "BDO": bdo,
    "BPI": bpi,
    "METROBANK": metro,
    "SECURITY BANK": security,
    "ROBINSONS": robinsons,
    "ADELFI": adelfi
}

export const BankImage = ({ bank }) => {

    return (
        <div className='text-center'>
            <img
                alt = "BANK IMAGE"
                src = { images[bank] }
                className = "bank-image"
                style = {{
                    maxWidth: 200,
                    maxHeight: 100,
                    margin: "10px auto"
                }}
            />
        </div>
    )
}