const bpi = [
    {
        label: "Account Name",
        value: "Faith Academy, Inc."
    },
    {
        label: "Peso Saving Account",
        value: "9721-0005-05"
    },
    {
        label: "Dollar Savings Account",
        value: "9724-0033-78"
    },
    {
        label: "Swift Code",
        value: "BOPIPHMM"
    }
]

export default bpi