import { useContext } from "react"
import FormContext from "../../../../../../form.context"
import { connect } from "../../api.utils"
import { useMaya } from "./maya.hooks"

export const MayaButton = () => {
    const {
        form,
        cardDetails,
        setStep,
        step,
        setLoading,
        isLoading } = useContext(FormContext)
    const maya      = useMaya()

    return (
        <button
            className   = "right-btn"
            disabled    = { isLoading || !Object.keys(step === 2 ? form : cardDetails).length }
            onClick     = { () => {
                            if (step === 2) setStep(3)
                            else {
                                const payload = { ...form, ...cardDetails, payment_type: "paymaya" }
                                
                                if (payload.payment_option === "Donation") {
                                    if (payload.donation_type === "Campus Development Projects") payload.donation_type += `:${ payload.projects }`
                                    payload.payment_details = payload.donation_type || "Student Assistance"
                                }
                                
                                setLoading(true)
                                connect().post("/api/transaction", payload)
                                    .then(res => {
                                        let referenceNumber = `${ res.data?.id }`
                                        const prefixCount  =  5 - referenceNumber.length

                                        if (prefixCount > 0) referenceNumber = `${ [ ...Array(prefixCount) ].map(() => '0').join('')}${ referenceNumber}`
                                        maya(form, cardDetails, referenceNumber)
                                    })
                                    .catch(() => { 
                                        setLoading(false)
                                        alert("Transaction was unsuccessful. Please try again.")
                                    })
                            }
                        } }
        >
            Proceed
        </button>
    )
}