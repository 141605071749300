import isoCodes from "../footer_ctas/components/payment_buttons/components/maya_button/country_codes"

const cardDetails = [
    // {
    //     label: "Card Number",
    //     name: "card_number",
    //     type: "password"
    // },
    // {
    //     label: "Expiration Date",
    //     name: "expiration_date",
    //     type: "exp-date"
    // },
    // {
    //     label: "CVV",
    //     name: "card_security_code",
    //     type: "numeric",
    //     decimal: 0
    // },
    {
        label: "Payor First Name",
        name: "biller_first_name"
    },
    {
        label: "Payor Last Name",
        name: "biller_last_name"
    },
    {
        label: "Payor Address",
        name: "biller_address1"
    },
    {
        label: "Payor State/Province",
        name: "biller_province"
    },
    {
        label: "Payor City",
        name: "biller_city"
    },
    {
        label: "Payor Country",
        name: "biller_country",
        type: "select",
        options: [ { label: "- Select your country -", value: '' }, ...Object.keys(isoCodes).map(code => ({ label: isoCodes[code].label, value: code })) ]
    },
    {
        label: "Payor Postal Code",
        name: "biller_postal_code"
    }
]

export default cardDetails