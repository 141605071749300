export const SelectedBankDetails = ({ details }) => (
    <div className="bank-details text-center">
        {
            details.map((d, i) => (
                <div
                    key     = {`bank-deets-${ i }`}
                    style   = {{ marginTop: 10 }}
                >
                    <div>
                        { d.label }
                    </div>
                    <div>
                        <strong>{ d.value }</strong>
                    </div>
                </div>
            ))
        }
    </div>
)