const controls = [
    {
        label: "Reason For Payment",
        name: "payment_option",
        type: "select",
        options: [
            { label: "Application Fee", value: "Application Fee" },
            { label: "Tuition & Fees", value: "Tuition & Fees" },
            { label: "Make a Donation", value: "Donation" },
            { label: "Top-up ID Card/Hot Lunch", value: "Top-up ID Card/Hot Lunch" },
            { label: "Other", value: "Other" }
        ]
    },
    {
        label: "Amount (*)",
        name: "amount",
        type: "numeric"
    },
    {
        label: "Donation Type",
        name: "donation_type",
        type: "select",
        options: [
            {
                label: "Student Assistance",
                value: "Student Assistance"
            },
            {
                label: "Staff Assistance",
                value: "Staff Assistance"
            },
            {
                label: "Compassion Fund",
                value: "Compassion Fund"
            },
            {
                label: "Greatest Need",
                value: "Greatest Need"
            },
            {
                label: "Campus Development Projects",
                value: "Campus Development Projects"
            }
        ],
        showOn : form => form.payment_option === "Donation"
    },
    {
        label: "Campus Development Projects",
        name: "projects",
        showOn : form => form.donation_type === "Campus Development Projects"
    },
    {
        label: "Payor Name",
        name: "payee_name"
    },
    {
        label: "Account/Student Number or N/A if not applicable",
        name: "account_number",
        type: "numeric",
        decimal: 0,
        showOn : form => form.payment_option === "Application Fee" || form.payment_option === "Tuition & Fees" || form.payment_option === "Top-up ID Card/Hot Lunch"
    },
    {
        label: "Grade/Level or N/A if not applicable",
        name: "grade",
        decimal: 0,
        showOn : form => form.payment_option === "Application Fee" || form.payment_option === "Tuition & Fees" || form.payment_option === "Top-up ID Card/Hot Lunch"
    },
    {
        label: "Student Name",
        name: "name",
        showOn : form => form.payment_option === "Application Fee" || form.payment_option === "Tuition & Fees" || form.payment_option === "Top-up ID Card/Hot Lunch"
    },
    {
        label: "Email Address",
        name: "email"
    },
    {
        label: "Contact Number",
        name: "contact_number",
        type: "number-string",
        decimal: 0
    },
    {
        label: "Payment Details",
        name: "payment_details",
        type: "textarea",
        showOn : form => form.payment_option === "Other"
    },
    {
        label: "Proof of Payment Attachment",
        name: "attachment",
        type: "file",
        showOn : form => form.method === "bank" || form.method === "qrph"
    },
    {
        label: "Date Deposited",
        name: "date_deposit",
        type: "datepicker",
        showOn : form => form.method === "bank"
    }
]

export default controls