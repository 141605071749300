import { useContext } from "react"
import moment from "moment"
import FormContext from "../../../../../form.context"
import { connect } from "../api.utils"

export const BankDepositButton = ({ bank, qr }) => {
    const { form, setStep, isLoading, setLoading} = useContext(FormContext)
    
    return (
        <button
            className   = "right-btn"
            disabled    = { isLoading || !Object.keys(form).length }
            onClick     = { () => {
                            if (form.attachment.size <= 1000000) {
                                const date  = form.date_deposit || new Date()
                                const month = date.getMonth() + 1
                                const day = date.getDate()
                                const depositDate = `${ date.getFullYear() }-${ month < 10 ? '0' : '' }${ month }-${ day < 10 ? '0' : '' }${ day }`
                                const newForm = new FormData()

                                Object.keys(form).forEach(key => {
                                    newForm.append(key, form[key])
                                })
                                newForm.append("reference_number", `${ form.account_number }-${ Date.now() }`)
                                newForm.append("payment_type", bank ? "bank_deposit" : "QRPH")
                                newForm.append("date_deposit", depositDate)
                                newForm.append("status", "pending")

                                if (form.payment_option === "Donation") {
                                    if (form.donation_type === "Campus Development Projects") form.donation_type += `:${ form.projects }`
                                    newForm.append("payment_details", form.donation_type || "Student Assistance")
                                }

                                setLoading(true)
                                connect().post("/api/transaction", newForm)
                                    .then((res) => {
                                        localStorage.setItem("paySession", JSON.stringify({ form, timeStamp: moment().unix(), referenceNumber: `${ res.data?.id }`, method: bank ? "bank_deposit" : "QRPH" }))
                                        setStep(4)
                                    })
                                    .catch(() => alert("Transaction was unsuccessful. Please try again."))
                                    .finally(() => setLoading(false))
                            }
                            else alert("Attachment size must not exceed 1mb")
                        } }
        >
            Proceed
        </button>
    )
}