const bdo = [
    {
        label: "Account Name",
        value: "Faith Academy, Inc."
    },
    {
        label: "Peso Saving Account",
        value: "005080147505"
    },
    {
        label: "Dollar Savings Account",
        value: "105080143674"
    },
    {
        label: "Swift Code",
        value: "BNORPHMM"
    }
]

export default bdo