export const errorMessages = {
    amount: {
        conform: "Amount minimum is $100"
    }
}

function formRules (data = {}) {
    return {
        properties: {
            ...(
                data.payment_type === "Application Fee" ? {
                    name: {
                        type: 'string',
                        required: true,
                        allowEmpty: false
                    },
                    grade: {
                        type: "string",
                        required: true,
                        allowEmpty: false
                    },
                    account_number: {
                        type: 'string',
                        required: true,
                        allowEmpty: false
                    }
                } : {}
            ),
            payee_name: {
                type: 'string',
                required: true,
                allowEmpty: false
            },
            email: {
                type: 'string',
                format: "email",
                required: true,
                allowEmpty: false
            },
            contact_number: {
                type: ['string', 'number'],
                required: true,
                allowEmpty: false
            },
            amount: {
                type: "string",
                required: true,
                allowEmpty: false,
                conform: () => {
                    if (data.currency === "USD" && +data.amount < 100) return false
                    return true
                }
            },
            ...(
                data.payment_type === "Other" ? {
                    payment_details: {
                        type: "string",
                        required: true,
                        allowEmpty: false
                    }
                } : {}
            ),
            ...(
                data.method === "bank" ? {
                    // payee_name: {
                    //     type: "string",
                    //     required: true,
                    //     allowEmpty: false
                    // },
                    attachment: {
                        type: "object",
                        required: true,
                        allowEmpty: false
                    }
                } : {}
            ),
            ...(
                data.payment_option === "Donation" && data.donation_type === "Campus Development Projects" ? {
                    projects: {
                        type: "string",
                        required: true,
                        allowEmpty: false
                    }
                } : {}
            )
        }
    }
}

export default formRules