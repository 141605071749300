import { PaymentButtons } from './components'
import './_styles.scss'

export const FooterCTAs = ({
    onLeftClick = () => {},
    onRightClick = () => {},
    disabled = false,
    method,
    step
}) => {

    return step < 4 ? (
        <div className="d-flex flex-column-reverse align-items-center flex-sm-row justify-content-between footer-ctas">
            <button
                className   = "left-btn d-flex align-items-center"
                onClick     = { onLeftClick }
            >
                <img
                    alt = "arrow left"
                    src = { require('../../arrow-left.png') }
                />
                Back
            </button>
            {
                (step === 2 || step === 3) && method ? (
                    <PaymentButtons method = { method } />
                ) : null
            }
        </div>
    ) : null
}