import { useContext } from "react"
import FormContext from "../../../form.context"
import QR from './QR.png'

export const QRPH = () => {
    const { method } = useContext(FormContext)

    return method === "qrph" ? (
        <div className='text-center'>
            <img
                alt = "QR"
                src = { QR }
                style = {{
                    maxWidth: 300,
                    maxHeight: 500,
                    margin: "10px auto"
                }}
            />
        </div>
    ) : null
}