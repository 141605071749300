import revalidator from 'revalidator'

export function validate(object, schema, options, hideName = false){
	let errors = {}
	let validation = revalidator.validate(object, schema, options)
	if (!validation.valid) {

		validation.errors.forEach((error) => {
			if (options?.messages && options.messages[error.property] && options.messages[error.property][error.attribute]) {
				errors[error.property] = options.messages[error.property][error.attribute]
			}
			else errors[error.property] = `This field ${error.message}`
		})
	}

	return {
		valid: validation.valid,
		errors: errors
	}
}
