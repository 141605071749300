import { useEffect } from 'react'
import moment from "moment"
import paymaya from 'paymaya-js-sdk'
import isoCodes from './country_codes'

export const useMaya = () => {
    const handleCheckout = (form = {}, cardDetails = {}, referenceNumber) => {
        const details           = { ...form, ...cardDetails, payment_type: "maya" }
        const amount            = +details.amount
        const countryCode       = isoCodes[details.biller_country.toLowerCase()].code
        const timeStamp         = moment().unix()

        localStorage.setItem("paySession", JSON.stringify({ form, cardDetails, timeStamp, referenceNumber, method: "maya" }))
        const checkoutDetails = {
                "totalAmount": {
                    "value": amount,
                    "currency": form.currency,
                    "details": {
                    "discount": 0,
                    "serviceCharge": 0,
                    "shippingFee": 0,
                    "tax": 0,
                    "subtotal": amount
                    }
                },
                "buyer": {
                    "firstName": details.biller_first_name,
                    "middleName": '',
                    "lastName": details.biller_last_name,
                    "birthday": "",
                    "customerSince": "",
                    "sex": "",
                    "contact": {
                        "phone": details.contact_number,
                        "email": details.email
                    },
                    "billingAddress": {
                    "line1": details.biller_address1,
                    "line2": "",
                    "city": details.biller_city,
                    "state": details.biller_province,
                    "zipCode": details.biller_postal_code,
                    "countryCode": countryCode
                    }
                },
                "items": [
                    {
                    "name": details.payment_option,
                    "quantity": 1,
                    "code": "",
                    "description": details.payment_details || `Payment for ${ details.payment_option }`,
                    "amount": {
                        "value": amount,
                        "details": {
                        "discount": 0,
                        "serviceCharge": 0,
                        "shippingFee": 0,
                        "tax": 0,
                        "subtotal": amount
                        }
                    },
                    "totalAmount": {
                        "value": amount,
                        "details": {
                        "discount": 0,
                        "serviceCharge": 0,
                        "shippingFee": 0,
                        "tax": 0,
                        "subtotal": amount
                        }
                    }
                }
            ],
            "redirectUrl": {
                "success": `${ process.env.NODE_ENV === "development" ? "http://localhost:3000" : "https://payment.faith.edu.ph"}/?mayaScc=${ timeStamp }`,
                "failure": `${ process.env.NODE_ENV === "development" ? "http://localhost:3000" : "https://payment.faith.edu.ph"}/?mayaErr=${ timeStamp }`,
                "cancel": `${ process.env.NODE_ENV === "development" ? "http://localhost:3000" : "https://payment.faith.edu.ph"}/?mayaCnl=${ timeStamp }`
            },
            "requestReferenceNumber": referenceNumber,
            "metadata": {
                "description": details.payment_details || `Payment for ${ details.payment_option }`
            }
        }

        paymaya.init(details.currency === "USD" ? process.env.REACT_APP_MAYA_PUBLIC_USD : process.env.REACT_APP_MAYA_PUBLIC, process.env.NODE_ENV !== "production")
        setTimeout(() => paymaya.createCheckout(checkoutDetails), 100)
    }

    return handleCheckout

}