import { BankDepositButton, MayaButton, PaypalButton } from "./components"

export const PaymentButtons = ({
    method
}) => {

    switch (method) {
        case "paypal": return <PaypalButton />
        case "bank": return <BankDepositButton bank/>
        case "qrph": return <BankDepositButton qr/>
        default: return <MayaButton />
    }
}