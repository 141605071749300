// import { useHistory } from 'react-router-dom'
import { useContext } from "react"
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js"
import FormContext from "../../../../../../form.context"
import { connect } from "../../api.utils"
import { CurrencyControl } from "./components"
import { useEffect } from "react"

export const PaypalButton = () => {
    const { form, method, setStep } = useContext(FormContext)

    useEffect(() => {
        window.sessionStorage.setItem("fPayload", JSON.stringify(form))
    }, [ form ])

    return (
        <div >
            <PayPalScriptProvider
                options = {{ "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID }}
            >
                <CurrencyControl currency={ form.currency }/>
                <PayPalButtons
                    
                    forceReRender   = {[ form.amount ]}
                    disabled        = { Object.keys(form).length === 0 }
                    onError         = { () => {
                                        alert("Transaction was unsuccessful. Please try again.")
                                    } }
                    createOrder     = { async (data, actions) => {
                                        let referenceNumber
                                        const newForm           = JSON.parse(window.sessionStorage.getItem("fPayload"))
                                        newForm.status          = "PENDING"
                                        newForm.payment_type    = method
                                        if (newForm.payment_option === "Donation") {
                                            if (newForm.donation_type === "Campus Development Projects") newForm.donation_type += `:${ newForm.projects }`
                                            newForm.payment_details = newForm.donation_type || "Student Assistance"
                                        }

                                        await connect().post("/api/transaction", newForm)
                                            .then(res => referenceNumber = `${ res.data?.id }`)

                                        return actions
                                                .order
                                                .create({
                                                    purchase_units: [ { amount: { value: +form.amount }, invoice_id: referenceNumber } ]
                                                })
                                                .then((orderId) => orderId)
                                    } }
                    onApprove       = { async (res, actions) => {
                                        
                                        return await actions.order.capture().then( async function(details) {
                                            const newForm = {
                                                requestReferenceNumber: details.purchase_units ? details.purchase_units[0].invoice_id : '',
                                                reference_number: details.id,
                                                status: details.status
                                            }
                                            
                                            let referenceNumber = `${ newForm.requestReferenceNumber }`
                                            const prefixCount  =  5 - referenceNumber.length
                                            if (prefixCount > 0) referenceNumber = `${ [ ...Array(prefixCount) ].map(() => '0').join('')}${ referenceNumber}`
                                            localStorage.setItem("paySession", JSON.stringify({ ...res.data, referenceNumber }))

                                            await connect().post("/api/transaction/paypal/hook", newForm).catch(err => {})
                                            setTimeout(() => setStep(4), 100)
                                        })
                                        .catch(err => console.log("etttt", err))
                                    } }
                    style           = {{ layout: "vertical" }}
                />
            </PayPalScriptProvider>
        </div>
    )
}