import { useEffect } from 'react'
import { usePayPalScriptReducer } from '@paypal/react-paypal-js'

export const CurrencyControl = ({ currency }) => {
    const [{ options }, dispatch] = usePayPalScriptReducer()

    useEffect(() => {
        dispatch({
            type: "resetOptions",
            value: {
                ...options,
                currency,
            },
        })
    }, [ currency ])

    return null
}