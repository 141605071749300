import { useEffect, useState } from 'react'

import check from './check.png'
import './_style.scss'

export const SuccessPage = ({ show }) => {
    const [ referenceNumber, setRefNum ] = useState('')
    const [ isBank, setIsBank ] = useState(false)

    useEffect(() => {
        const session = localStorage.getItem("paySession")
        if (session) {
            const parseSession = JSON.parse(session)
            setIsBank(parseSession.method === "bank_deposit")
            setRefNum(parseSession.referenceNumber)
            setTimeout(() => {
                localStorage.removeItem("paySession")
            }, 1000)
        }
    }, [ show ])

    return show ? (
        <div id = "successPage">
            <img
                alt = "check"
                src = { check }
                className = "check-icon"
            />
            <div className='h1'>
                { isBank ? " You will be notified once deposit is verified" : "Payment Successful!" }
            </div>
            <div className='p'>
                Transaction Number
            </div>
            <div className='h3'>
                <strong>{ referenceNumber }</strong>
            </div>
            <div className='p'>
                { isBank ? "" : "An email notification was sent for your successful payment." }
                <br/>
                Contact <a href="mailto:business.office@faith.edu.ph">business.office@faith.edu.ph</a> for any concern.
            </div>
            <a className="site-link" href = "https://faith.edu.ph/">
                Back to Site
            </a>
            <button
                onClick = { () => {
                    window.location.reload()
                }}
            >
                Make another transaction
            </button>
        </div>
    ) : null
}