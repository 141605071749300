import { useState, useEffect, useContext } from 'react'
import { Input } from '../input'
import { TextArea } from '../text_area'
import { Select } from '../select'
import { FileInput } from '../file_input'
import './_styles.scss'
import controls from './controls.map'
import cardDetails from './card_details.map'
import rules, { errorMessages } from './validation.rules'
import cardRules from './card_details.rules'
import { validate } from './validation.utils'
import FormContext from '../../form.context'
import { BankOptions, QRPH } from './components'
import { DatePicker } from '../date_picker'

export const PaymentInfo = ({ show }) => {
    const formContext           = useContext(FormContext)
    const [ bank, setBank ]     = useState("BDO")
    const { onFormChange, onCardChange, step, method } = formContext
    const [ form, setForm ]     = useState({ payment_option : "Application Fee", currency: "USD" })
    const activeControls        = step === 2 ? controls : cardDetails
    const activeRules           = step === 2 ? rules : cardRules
    const [ errors, setErrors ] = useState({})

    const handleChange          = ({ name, value }) => {
                                    setForm(prev => {
                                        const newForm = { ...prev }
                                        newForm[name] = value

                                        const validation = validate(newForm, activeRules({ ...newForm, method }), { messages: errorMessages })
                                        setErrors(prev => {
                                            return { ...prev, [name]: validation.errors[name] }
                                        })

                                        return newForm
                                    })
                                }

    useEffect(() => {
        if (step === 2) {
            const validation = validate(form, rules({ ...form, method }), { messages: errorMessages })
            onFormChange(validation.valid ? { ...form, ...( bank ? { bank } : {} )} : {})
        }
        else {
            const validation = validate(form, cardRules())
            onCardChange(validation.valid ? form : {})
        }
    }, [ form ])

    useEffect(() => {
        if (!localStorage.getItem("mayaSession")) {
            const phpCondition = method === "qrph"
            if (step === 2) setForm({ payment_option : "Application Fee", currency: phpCondition ? "PHP" : "USD", ...formContext.form, biller_country: "Philippines" })
            else setForm({ ...formContext.cardDetails })
        }
        else {
            if (step === 2) setForm(formContext.form)
            else setForm(formContext.cardDetails)
        }
    }, [ step ])

    useEffect(() => {
        if (!localStorage.getItem("mayaSession")) {
            setForm({ payment_option : "Application Fee", currency: method === "qrph" ? "PHP" : "USD", biller_country: "Philippines" })
        }
    }, [ method ])

    useEffect(() => {
        if (method === "bank") setForm(prev => ({ ...prev, currency: bank === "SECURITY BANK" || bank === "ROBINSONS" ? "PHP" : "USD" }))
    }, [ bank, method ])

    return show ? (
        <div className='payment-info'>
            <BankOptions onChange = { setBank }/>
            <QRPH />
            {
                activeControls.map(control => {
                    switch(control.type) {
                        case "select": {
                            return (!control.showOn || control.showOn({ ...form, method })) ? (
                                <Select
                                    key = { control.name }
                                    { ...control }
                                    onChange = { handleChange }
                                    value = { form[control.name] }
                                    error = { errors[control.name] }
                                />
                            ) : null
                        }
                        case "textarea": {
                            return (
                                <TextArea
                                    key = { control.name }
                                    { ...control }
                                    onChange = { handleChange }
                                    value = { form[control.name] }
                                    show  = { control.showOn(form) }
                                    error = { errors[control.name] }
                                />
                            )
                        }
                        case "file": {
                            return (!control.showOn || control.showOn({ ...form, method })) ? (
                                <FileInput
                                    key = { control.name }
                                    { ...control }
                                    onChange = { handleChange }
                                    error = { errors[control.name] }
                                />
                            ) : null
                        }
                        case "datepicker": {
                            return (!control.showOn || control.showOn({ ...form, method })) ? (
                                <DatePicker
                                    key = { control.name }
                                    { ...control }
                                    value = { form[control.name] }
                                    onChange = { handleChange }
                                    error = { errors[control.name] }
                                />
                            ) : null
                        }
                        default: {
                            let label = control.label

                            if (control.name === "amount") label = control.label.replace('*', form.currency === "USD" ? '$' : '₱')
                            return (!control.showOn || control.showOn({ ...form, method })) ? (
                                <div className='d-flex fth-input' key = { control.name }>
                                    <Input
                                        { ...{ ...control, label } }
                                        onChange = { handleChange }
                                        value = { form[control.name] }
                                        error = { errors[control.name] }
                                    />
                                    {
                                        (method === "paypal" || method === "maya" || (method === "bank" && bank !== "ADELFI" && bank !== "SECURITY BANK" && bank !== "ROBINSONS")) && control.name === "amount" ? (
                                            <Select
                                                name = "currency"
                                                options = {[
                                                    { label: "USD", value: "USD" },
                                                    { label: "PHP", value: "PHP" }
                                                ]}
                                                className = "currency-selector"
                                                onChange = { handleChange }
                                                value = { form.currency }
                                            />
                                        ) : null
                                    }
                                </div>
                            ) : null
                        }
                    }
                })
            }
        </div>
    ) : null
}