import './_styles.scss'
import bg from './bg.jpg'

export const LeftSection = ({ show }) => {
    const year = new Date().getFullYear()

    return show ? (
        <div className='d-flex left-section-wrapper'>
            <img
                src = { bg }
                alt = ""
                className = "wrapper-bg"
            />
            <div className='left-text-content text-center'>
                <h1 className='font-lovely'>
                    Welcome
                </h1>
                <div>
                    But those who hope in the Lord will renew their strength. They will soar on wings like eagles; they will run and not grow weary, they will walk and not be faint.
                </div>
                <div className='verse-ch'>
                    ISAIAH 40:31
                </div>
            </div>
            <div className='copy-rights'>
                <img
                    alt = "logo"
                    src = { require('./faith_logo.png') }
                    className = "f-logo"
                />
                <div className='txt-center'>
                    Copyright © { year } Faith Academy
                </div>
            </div>
        </div>
    ) : null
}