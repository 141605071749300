import { Converter } from './components'

export const Header = ({ show, pesoRate }) => {

    return show ? (
        <div className="form-header d-flex">
            <div className="header-icon">
                <img
                    src = { require('../../list-text.png') }
                />
            </div>
            <h4>
                Payment
            </h4>
            {
                pesoRate ? (
                    <div className="conversion">
                        1USD = { pesoRate }PHP
                        <Converter pesoRate = { pesoRate } />
                    </div>
                ) : null
            }
        </div>
    ) : null
}