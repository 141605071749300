const adelfi = [
    {
        label: "(Formerly Evangelical Christian Credit Union - ECCU)"
    },
    {
        label: "Account Name",
        value: "Faith Academy, Inc."
    },
    {
        label: "Dollar Checking Account",
        value: "1006497"
    },
    {
        label: "Routing Number",
        value: "322273379"
    }
]

export default adelfi