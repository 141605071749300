const isoCodes = {
    "afghanistan": {
        "code": "AF",
        "label": "Afghanistan"
    },
    "albania": {
        "code": "AL",
        "label": "Albania"
    },
    "algeria": {
        "code": "DZ",
        "label": "Algeria"
    },
    "american samoa": {
        "code": "AS",
        "label": "American Samoa"
    },
    "andorra": {
        "code": "AD",
        "label": "Andorra"
    },
    "angola": {
        "code": "AO",
        "label": "Angola"
    },
    "anguilla": {
        "code": "AI",
        "label": "Anguilla"
    },
    "antarctica": {
        "code": "AQ",
        "label": "Antarctica"
    },
    "antigua and barbuda": {
        "code": "AG",
        "label": "Antigua and Barbuda"
    },
    "argentina": {
        "code": "AR",
        "label": "Argentina"
    },
    "armenia": {
        "code": "AM",
        "label": "Armenia"
    },
    "aruba": {
        "code": "AW",
        "label": "Aruba"
    },
    "australia": {
        "code": "AU",
        "label": "Australia"
    },
    "austria": {
        "code": "AT",
        "label": "Austria"
    },
    "azerbaijan": {
        "code": "AZ",
        "label": "Azerbaijan"
    },
    "bahamas": {
        "code": "BS",
        "label": "Bahamas"
    },
    "bahrain": {
        "code": "BH",
        "label": "Bahrain"
    },
    "bangladesh": {
        "code": "BD",
        "label": "Bangladesh"
    },
    "barbados": {
        "code": "BB",
        "label": "Barbados"
    },
    "belarus": {
        "code": "BY",
        "label": "Belarus"
    },
    "belgium": {
        "code": "BE",
        "label": "Belgium"
    },
    "belize": {
        "code": "BZ",
        "label": "Belize"
    },
    "benin": {
        "code": "BJ",
        "label": "Benin"
    },
    "bermuda": {
        "code": "BM",
        "label": "Bermuda"
    },
    "bhutan": {
        "code": "BT",
        "label": "Bhutan"
    },
    "bolivia": {
        "code": "BO",
        "label": "Bolivia"
    },
    "bosnia and herzegovina": {
        "code": "BA",
        "label": "Bosnia and Herzegovina"
    },
    "botswana": {
        "code": "BW",
        "label": "Botswana"
    },
    "brazil": {
        "code": "BR",
        "label": "Brazil"
    },
    "british indian ocean territory": {
        "code": "IO",
        "label": "British Indian Ocean Territory"
    },
    "british virgin islands": {
        "code": "VG",
        "label": "British Virgin Islands"
    },
    "brunei": {
        "code": "BN",
        "label": "Brunei"
    },
    "bulgaria": {
        "code": "BG",
        "label": "Bulgaria"
    },
    "burkina faso": {
        "code": "BF",
        "label": "Burkina Faso"
    },
    "burundi": {
        "code": "BI",
        "label": "Burundi"
    },
    "cambodia": {
        "code": "KH",
        "label": "Cambodia"
    },
    "cameroon": {
        "code": "CM",
        "label": "Cameroon"
    },
    "canada": {
        "code": "CA",
        "label": "Canada"
    },
    "cape verde": {
        "code": "CV",
        "label": "Cape Verde"
    },
    "cayman islands": {
        "code": "KY",
        "label": "Cayman Islands"
    },
    "central african republic": {
        "code": "CF",
        "label": "Central African Republic"
    },
    "chad": {
        "code": "TD",
        "label": "Chad"
    },
    "chile": {
        "code": "CL",
        "label": "Chile"
    },
    "china": {
        "code": "CN",
        "label": "China"
    },
    "christmas island": {
        "code": "CX",
        "label": "Christmas Island"
    },
    "cocos islands": {
        "code": "CC",
        "label": "Cocos Islands"
    },
    "colombia": {
        "code": "CO",
        "label": "Colombia"
    },
    "comoros": {
        "code": "KM",
        "label": "Comoros"
    },
    "cook islands": {
        "code": "CK",
        "label": "Cook Islands"
    },
    "costa rica": {
        "code": "CR",
        "label": "Costa Rica"
    },
    "croatia": {
        "code": "HR",
        "label": "Croatia"
    },
    "cuba": {
        "code": "CU",
        "label": "Cuba"
    },
    "curacao": {
        "code": "CW",
        "label": "Curacao"
    },
    "cyprus": {
        "code": "CY",
        "label": "Cyprus"
    },
    "czech republic": {
        "code": "CZ",
        "label": "Czech Republic"
    },
    "democratic republic of the congo": {
        "code": "CD",
        "label": "Democratic Republic of the Congo"
    },
    "denmark": {
        "code": "DK",
        "label": "Denmark"
    },
    "djibouti": {
        "code": "DJ",
        "label": "Djibouti"
    },
    "dominica": {
        "code": "DM",
        "label": "Dominica"
    },
    "dominican republic": {
        "code": "DO",
        "label": "Dominican Republic"
    },
    "east timor": {
        "code": "TL",
        "label": "East Timor"
    },
    "ecuador": {
        "code": "EC",
        "label": "Ecuador"
    },
    "egypt": {
        "code": "EG",
        "label": "Egypt"
    },
    "el salvador": {
        "code": "SV",
        "label": "El Salvador"
    },
    "equatorial guinea": {
        "code": "GQ",
        "label": "Equatorial Guinea"
    },
    "eritrea": {
        "code": "ER",
        "label": "Eritrea"
    },
    "estonia": {
        "code": "EE",
        "label": "Estonia"
    },
    "ethiopia": {
        "code": "ET",
        "label": "Ethiopia"
    },
    "falkland islands": {
        "code": "FK",
        "label": "Falkland Islands"
    },
    "faroe islands": {
        "code": "FO",
        "label": "Faroe Islands"
    },
    "fiji": {
        "code": "FJ",
        "label": "Fiji"
    },
    "finland": {
        "code": "FI",
        "label": "Finland"
    },
    "france": {
        "code": "FR",
        "label": "France"
    },
    "french polynesia": {
        "code": "PF",
        "label": "French Polynesia"
    },
    "gabon": {
        "code": "GA",
        "label": "Gabon"
    },
    "gambia": {
        "code": "GM",
        "label": "Gambia"
    },
    "georgia": {
        "code": "GE",
        "label": "Georgia"
    },
    "germany": {
        "code": "DE",
        "label": "Germany"
    },
    "ghana": {
        "code": "GH",
        "label": "Ghana"
    },
    "gibraltar": {
        "code": "GI",
        "label": "Gibraltar"
    },
    "greece": {
        "code": "GR",
        "label": "Greece"
    },
    "greenland": {
        "code": "GL",
        "label": "Greenland"
    },
    "grenada": {
        "code": "GD",
        "label": "Grenada"
    },
    "guam": {
        "code": "GU",
        "label": "Guam"
    },
    "guatemala": {
        "code": "GT",
        "label": "Guatemala"
    },
    "guernsey": {
        "code": "GG",
        "label": "Guernsey"
    },
    "guinea": {
        "code": "GN",
        "label": "Guinea"
    },
    "guinea-bissau": {
        "code": "GW",
        "label": "Guinea-Bissau"
    },
    "guyana": {
        "code": "GY",
        "label": "Guyana"
    },
    "haiti": {
        "code": "HT",
        "label": "Haiti"
    },
    "honduras": {
        "code": "HN",
        "label": "Honduras"
    },
    "hong kong": {
        "code": "HK",
        "label": "Hong Kong"
    },
    "hungary": {
        "code": "HU",
        "label": "Hungary"
    },
    "iceland": {
        "code": "IS",
        "label": "Iceland"
    },
    "india": {
        "code": "IN",
        "label": "India"
    },
    "indonesia": {
        "code": "ID",
        "label": "Indonesia"
    },
    "iran": {
        "code": "IR",
        "label": "Iran"
    },
    "iraq": {
        "code": "IQ",
        "label": "Iraq"
    },
    "ireland": {
        "code": "IE",
        "label": "Ireland"
    },
    "isle of man": {
        "code": "IM",
        "label": "Isle of Man"
    },
    "israel": {
        "code": "IL",
        "label": "Israel"
    },
    "italy": {
        "code": "IT",
        "label": "Italy"
    },
    "ivory coast": {
        "code": "CI",
        "label": "Ivory Coast"
    },
    "jamaica": {
        "code": "JM",
        "label": "Jamaica"
    },
    "japan": {
        "code": "JP",
        "label": "Japan"
    },
    "jersey": {
        "code": "JE",
        "label": "Jersey"
    },
    "jordan": {
        "code": "JO",
        "label": "Jordan"
    },
    "kazakhstan": {
        "code": "KZ",
        "label": "Kazakhstan"
    },
    "kenya": {
        "code": "KE",
        "label": "Kenya"
    },
    "kiribati": {
        "code": "KI",
        "label": "Kiribati"
    },
    "kosovo": {
        "code": "XK",
        "label": "Kosovo"
    },
    "kuwait": {
        "code": "KW",
        "label": "Kuwait"
    },
    "kyrgyzstan": {
        "code": "KG",
        "label": "Kyrgyzstan"
    },
    "laos": {
        "code": "LA",
        "label": "Laos"
    },
    "latvia": {
        "code": "LV",
        "label": "Latvia"
    },
    "lebanon": {
        "code": "LB",
        "label": "Lebanon"
    },
    "lesotho": {
        "code": "LS",
        "label": "Lesotho"
    },
    "liberia": {
        "code": "LR",
        "label": "Liberia"
    },
    "libya": {
        "code": "LY",
        "label": "Libya"
    },
    "liechtenstein": {
        "code": "LI",
        "label": "Liechtenstein"
    },
    "lithuania": {
        "code": "LT",
        "label": "Lithuania"
    },
    "luxembourg": {
        "code": "LU",
        "label": "Luxembourg"
    },
    "macao": {
        "code": "MO",
        "label": "Macao"
    },
    "macedonia": {
        "code": "MK",
        "label": "Macedonia"
    },
    "madagascar": {
        "code": "MG",
        "label": "Madagascar"
    },
    "malawi": {
        "code": "MW",
        "label": "Malawi"
    },
    "malaysia": {
        "code": "MY",
        "label": "Malaysia"
    },
    "maldives": {
        "code": "MV",
        "label": "Maldives"
    },
    "mali": {
        "code": "ML",
        "label": "Mali"
    },
    "malta": {
        "code": "MT",
        "label": "Malta"
    },
    "marshall islands": {
        "code": "MH",
        "label": "Marshall Islands"
    },
    "mauritania": {
        "code": "MR",
        "label": "Mauritania"
    },
    "mauritius": {
        "code": "MU",
        "label": "Mauritius"
    },
    "mayotte": {
        "code": "YT",
        "label": "Mayotte"
    },
    "mexico": {
        "code": "MX",
        "label": "Mexico"
    },
    "micronesia": {
        "code": "FM",
        "label": "Micronesia"
    },
    "moldova": {
        "code": "MD",
        "label": "Moldova"
    },
    "monaco": {
        "code": "MC",
        "label": "Monaco"
    },
    "mongolia": {
        "code": "MN",
        "label": "Mongolia"
    },
    "montenegro": {
        "code": "ME",
        "label": "Montenegro"
    },
    "montserrat": {
        "code": "MS",
        "label": "Montserrat"
    },
    "morocco": {
        "code": "MA",
        "label": "Morocco"
    },
    "mozambique": {
        "code": "MZ",
        "label": "Mozambique"
    },
    "myanmar": {
        "code": "MM",
        "label": "Myanmar"
    },
    "namibia": {
        "code": "NA",
        "label": "Namibia"
    },
    "nauru": {
        "code": "NR",
        "label": "Nauru"
    },
    "nepal": {
        "code": "NP",
        "label": "Nepal"
    },
    "netherlands": {
        "code": "NL",
        "label": "Netherlands"
    },
    "netherlands antilles": {
        "code": "AN",
        "label": "Netherlands Antilles"
    },
    "new caledonia": {
        "code": "NC",
        "label": "New Caledonia"
    },
    "new zealand": {
        "code": "NZ",
        "label": "New Zealand"
    },
    "nicaragua": {
        "code": "NI",
        "label": "Nicaragua"
    },
    "niger": {
        "code": "NE",
        "label": "Niger"
    },
    "nigeria": {
        "code": "NG",
        "label": "Nigeria"
    },
    "niue": {
        "code": "NU",
        "label": "Niue"
    },
    "north korea": {
        "code": "KP",
        "label": "North Korea"
    },
    "northern mariana islands": {
        "code": "MP",
        "label": "Northern Mariana Islands"
    },
    "norway": {
        "code": "NO",
        "label": "Norway"
    },
    "oman": {
        "code": "OM",
        "label": "Oman"
    },
    "pakistan": {
        "code": "PK",
        "label": "Pakistan"
    },
    "palau": {
        "code": "PW",
        "label": "Palau"
    },
    "palestine": {
        "code": "PS",
        "label": "Palestine"
    },
    "panama": {
        "code": "PA",
        "label": "Panama"
    },
    "papua new guinea": {
        "code": "PG",
        "label": "Papua New Guinea"
    },
    "paraguay": {
        "code": "PY",
        "label": "Paraguay"
    },
    "peru": {
        "code": "PE",
        "label": "Peru"
    },
    "philippines": {
        "code": "PH",
        "label": "Philippines"
    },
    "pitcairn": {
        "code": "PN",
        "label": "Pitcairn"
    },
    "poland": {
        "code": "PL",
        "label": "Poland"
    },
    "portugal": {
        "code": "PT",
        "label": "Portugal"
    },
    "puerto rico": {
        "code": "PR",
        "label": "Puerto Rico"
    },
    "qatar": {
        "code": "QA",
        "label": "Qatar"
    },
    "republic of the congo": {
        "code": "CG",
        "label": "Republic of the Congo"
    },
    "reunion": {
        "code": "RE",
        "label": "Reunion"
    },
    "romania": {
        "code": "RO",
        "label": "Romania"
    },
    "russia": {
        "code": "RU",
        "label": "Russia"
    },
    "rwanda": {
        "code": "RW",
        "label": "Rwanda"
    },
    "saint barthelemy": {
        "code": "BL",
        "label": "Saint Barthelemy"
    },
    "saint helena": {
        "code": "SH",
        "label": "Saint Helena"
    },
    "saint kitts and nevis": {
        "code": "KN",
        "label": "Saint Kitts and Nevis"
    },
    "saint lucia": {
        "code": "LC",
        "label": "Saint Lucia"
    },
    "saint martin": {
        "code": "MF",
        "label": "Saint Martin"
    },
    "saint pierre and miquelon": {
        "code": "PM",
        "label": "Saint Pierre and Miquelon"
    },
    "saint vincent and the grenadines": {
        "code": "VC",
        "label": "Saint Vincent and the Grenadines"
    },
    "samoa": {
        "code": "WS",
        "label": "Samoa"
    },
    "san marino": {
        "code": "SM",
        "label": "San Marino"
    },
    "sao tome and principe": {
        "code": "ST",
        "label": "Sao Tome and Principe"
    },
    "saudi arabia": {
        "code": "SA",
        "label": "Saudi Arabia"
    },
    "senegal": {
        "code": "SN",
        "label": "Senegal"
    },
    "serbia": {
        "code": "RS",
        "label": "Serbia"
    },
    "seychelles": {
        "code": "SC",
        "label": "Seychelles"
    },
    "sierra leone": {
        "code": "SL",
        "label": "Sierra Leone"
    },
    "singapore": {
        "code": "SG",
        "label": "Singapore"
    },
    "sint maarten": {
        "code": "SX",
        "label": "Sint Maarten"
    },
    "slovakia": {
        "code": "SK",
        "label": "Slovakia"
    },
    "slovenia": {
        "code": "SI",
        "label": "Slovenia"
    },
    "solomon islands": {
        "code": "SB",
        "label": "Solomon Islands"
    },
    "somalia": {
        "code": "SO",
        "label": "Somalia"
    },
    "south africa": {
        "code": "ZA",
        "label": "South Africa"
    },
    "south korea": {
        "code": "KR",
        "label": "South Korea"
    },
    "south sudan": {
        "code": "SS",
        "label": "South Sudan"
    },
    "spain": {
        "code": "ES",
        "label": "Spain"
    },
    "sri lanka": {
        "code": "LK",
        "label": "Sri Lanka"
    },
    "sudan": {
        "code": "SD",
        "label": "Sudan"
    },
    "suriname": {
        "code": "SR",
        "label": "Suriname"
    },
    "svalbard and jan mayen": {
        "code": "SJ",
        "label": "Svalbard and Jan Mayen"
    },
    "swaziland": {
        "code": "SZ",
        "label": "Swaziland"
    },
    "sweden": {
        "code": "SE",
        "label": "Sweden"
    },
    "switzerland": {
        "code": "CH",
        "label": "Switzerland"
    },
    "syria": {
        "code": "SY",
        "label": "Syria"
    },
    "taiwan": {
        "code": "TW",
        "label": "Taiwan"
    },
    "tajikistan": {
        "code": "TJ",
        "label": "Tajikistan"
    },
    "tanzania": {
        "code": "TZ",
        "label": "Tanzania"
    },
    "thailand": {
        "code": "TH",
        "label": "Thailand"
    },
    "togo": {
        "code": "TG",
        "label": "Togo"
    },
    "tokelau": {
        "code": "TK",
        "label": "Tokelau"
    },
    "tonga": {
        "code": "TO",
        "label": "Tonga"
    },
    "trinidad and tobago": {
        "code": "TT",
        "label": "Trinidad and Tobago"
    },
    "tunisia": {
        "code": "TN",
        "label": "Tunisia"
    },
    "turkey": {
        "code": "TR",
        "label": "Turkey"
    },
    "turkmenistan": {
        "code": "TM",
        "label": "Turkmenistan"
    },
    "turks and caicos islands": {
        "code": "TC",
        "label": "Turks and Caicos Islands"
    },
    "tuvalu": {
        "code": "TV",
        "label": "Tuvalu"
    },
    "u.s. virgin islands": {
        "code": "VI",
        "label": "U.S. Virgin Islands"
    },
    "uganda": {
        "code": "UG",
        "label": "Uganda"
    },
    "ukraine": {
        "code": "UA",
        "label": "Ukraine"
    },
    "united arab emirates": {
        "code": "AE",
        "label": "United Arab Emirates"
    },
    "united kingdom": {
        "code": "GB",
        "label": "United Kingdom"
    },
    "united states": {
        "code": "US",
        "label": "United States"
    },
    "uruguay": {
        "code": "UY",
        "label": "Uruguay"
    },
    "uzbekistan": {
        "code": "UZ",
        "label": "Uzbekistan"
    },
    "vanuatu": {
        "code": "VU",
        "label": "Vanuatu"
    },
    "vatican": {
        "code": "VA",
        "label": "Vatican"
    },
    "venezuela": {
        "code": "VE",
        "label": "Venezuela"
    },
    "vietnam": {
        "code": "VN",
        "label": "Vietnam"
    },
    "wallis and futuna": {
        "code": "WF",
        "label": "Wallis and Futuna"
    },
    "western sahara": {
        "code": "EH",
        "label": "Western Sahara"
    },
    "yemen": {
        "code": "YE",
        "label": "Yemen"
    },
    "zambia": {
        "code": "ZM",
        "label": "Zambia"
    },
    "zimbabwe": {
        "code": "ZW",
        "label": "Zimbabwe"
    }
}

export default isoCodes