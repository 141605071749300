const metro = [
    {
        label: "Account Name",
        value: "Faith Academy, Inc."
    },
    {
        label: "Peso Saving Account",
        value: "276-3-276-80161-0"
    },
    {
        label: "Dollar Savings Account",
        value: "276-2-276-00080-7"
    },
    {
        label: "Swift Code",
        value: "MBTCPHMM"
    }
]

export default metro